import { ButtonBase, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { graphql } from 'gatsby'
import gsap, { Power1 } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useCallback, useEffect, useState } from 'react'
import traumaImage from '../assets/Q1.jpg'
import traumaHoverImage from '../assets/Q1_hover.jpg'
import strengthImage from '../assets/Q2.jpg'
import strengthHoverImage from '../assets/Q2_hover.jpg'
import activityImage from '../assets/Q3.jpg'
import activityHoverImage from '../assets/Q3_hover.jpg'
import selImage from '../assets/Q4.jpg'
import selHoverImage from '../assets/Q4_hover.jpg'
import welcomeFigureImage from '../assets/welcome_figure.png'
import welcomeStarImage from '../assets/welcome_star.png'
import ContentContainer from '../components/ContentContainer'
import LocalizedLink from '../components/LocalizedLink'
import GraphQLErrorList from '../components/graphql-error-list'
import CloseIcon from '../components/icons/close'
import SEO from '../components/seo'
import LayoutContainer from '../containers/layout'
import { convertLineBreakToBr, flattenSectionsFromGraphQL } from '../lib/helpers'
import useLocalStorage from '../lib/useLocalStorage'
import baseTheme from '../styles/theme'

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
  gsap.core.globals('ScrollTrigger', ScrollTrigger)
}

export const query = graphql`
  query IndexPageQuery($language: String) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      language
      description
      aboutThisProject
      activityCorner
      aResourceSiteBy
    }

    localizedSiteSettings: sanitySiteSettings(language: { eq: $language }) {
      id
      title
      language
      description
      aboutThisProject
      activityCorner
      aResourceSiteBy
    }

    localizedSections: allSanitySection(
      filter: { slug: { current: { ne: null } }, language: { eq: $language } }
    ) {
      edges {
        node {
          id
          _id
          language
          title
          slug {
            current
          }
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  '@keyframes blinker': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes bounce': {
    from: {
      transform: 'translateY(0)',
    },
    to: {
      transform: 'translateY(-12px)',
    },
  },
  layoutInvisible: {
    opacity: 0,
  },
  layoutVisible: {
    opacity: 1,
  },
  scrollSpacer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 'calc(100vh + 400px)',
  },
  welcomeContentContainer: {
    display: 'grid',
    gridTemplateColumns: '3fr [content] minmax(0, 768px) 3fr',
    gridTemplateRows: 'min-content [image] 1fr',
    paddingTop: 60,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr [content] 12fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '[content] 1fr',
      padding: '40px 20px 0',
    },
  },
  welcomeContent: {
    gridColumn: 'content',
    textAlign: 'center',
  },
  welcomeImageContainer: {
    gridColumn: 'content',
    textAlign: 'center',
    overflow: 'hidden',
    position: 'relative',
    background: 'none',
    outline: 'none',
    border: 'none',
    display: 'block',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  welcomeImage: {
    height: '100%',
    width: 'auto',
    display: 'block',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    bottom: 0,
    // '@media (orientation: portrait)': {
    //   height: 'auto',
    //   width: '100%'
    // }
  },
  welcomeStarImage: {},
  welcomeArrow: {
    position: 'absolute',
    top: 10,
    left: '50%',
    marginLeft: '-5px',
    width: 1,
    height: 10,
    borderRight: '1px solid black',
    '&:after': {
      position: 'absolute',
      bottom: 0,
      content: '""',
      display: 'block',
      width: 10,
      height: 10,
      borderRight: '1px solid black',
      borderBottom: '1px solid black',
      transformOrigin: 'center center',
      transform: 'rotate(45deg) translateX(-5px) translateY(2px)',
    },
    '&.bounce': {
      animation: '$bounce .875s',
      animationDirection: 'alternate',
      animationTimingFunction: 'cubic-bezier(0,0,.58,1)',
      animationIterationCount: 'infinite',
    },
  },
  welcomeText: {
    lineHeight: 1.5,
    gridColumn: 'content',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
      marginBottom: '1em',
    },
  },
  welcomeButton: {
    width: 156,
    height: 156,
    borderRadius: '50%',
    border: '1px solid white',
    transition: 'all .3s ease',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  homeContentContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    position: 'fixed',
    top: 0,
    opacity: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
  },
  homeBlocksContainer: {
    height: '70vh',
    width: '140vh',
    position: 'relative',
    margin: 'auto',
    display: 'grid',
    gridTemplateRows: '[one] 1fr [two] 1fr',
    gridTemplateColumns: '[one] 1fr [two] 1fr',
    [theme.breakpoints.down('sm')]: {
      width: '75vw',
      height: '75vw',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: '75vh',
      height: '75vh',
    },
  },
  homeBlock: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    '&.hover, &.active': {
      zIndex: 10,
    },
    '& .hover-img': {
      opacity: 0,
    },
    '&.hover .hover-img, &.active .hover-img': {
      opacity: 1,
    },
    '&.hover .block-title, &.active .block-title': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  blockTitle: {
    position: 'absolute',
    bottom: -9,
    opacity: 0,
    transition: 'all .4s ease',
    textAlign: 'left',
    maxWidth: '6em',
    pointerEvents: 'none',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      bottom: 'auto',
    },
  },
  blockImage: {
    position: 'absolute',
    height: '100%',
    transition: 'all .4s ease',
  },
  homeBlockTrauma: {
    gridRow: 'one',
    gridColumn: 'one',
    '& .block-title': {
      right: '62%',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        top: '-2.75em',
        right: 'auto',
        left: 0,
        textAlign: 'left',
        transform: 'translateX(-13px)',
      },
    },
    '& img': {
      bottom: -1,
      right: -1,
    },
    '&.hover img, &.active img': {
      transform: 'translateX(-40px)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(-20px)',
      },
    },
  },
  homeBlockStrength: {
    gridRow: 'one',
    gridColumn: 'two',
    '& .block-title': {
      left: '62%',
      [theme.breakpoints.down('sm')]: {
        top: '-2.75em',
        left: 'auto',
        right: 0,
        textAlign: 'right',
        transform: 'translateX(10px)',
      },
    },
    '& img': {
      bottom: -1,
      left: -1,
    },
    '&.hover img, &.active img': {
      transform: 'translateX(40px)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(20px)',
      },
    },
  },
  homeBlockSEL: {
    gridRow: 'two',
    gridColumn: 'one',
    '& .block-title': {
      right: '62%',
      textAlign: 'right',
      bottom: 0,
      [theme.breakpoints.down('sm')]: {
        bottom: '-2.5em',
        right: 'auto',
        left: 0,
        textAlign: 'left',
        transform: 'translateX(-13px)',
      },
    },
    '& img': {
      top: 0,
      right: -1,
    },
    '&.hover img, &.active img': {
      transform: 'translateX(-40px)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(-20px)',
      },
    },
  },
  homeBlockActivity: {
    gridRow: 'two',
    gridColumn: 'two',
    '& .block-title': {
      left: '70%',
      bottom: 0,
      [theme.breakpoints.down('sm')]: {
        bottom: '-2.5em',
        left: 'auto',
        right: 0,
        textAlign: 'right',
        transform: 'translateX(10px)',
      },
    },
    '& img': {
      top: 0,
      left: -1,
    },
    '&.hover img, &.active img': {
      transform: 'translateX(40px)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(20px)',
      },
    },
  },
  pill: {
    display: 'inline-block',
    background: '#FCCC4B',
    padding: '.25em .75em',
    borderRadius: '.75em',
    marginBottom: '1em',
  },
  touchNotice: {
    display: 'inline-block',
    background: baseTheme.custom.colors.textBlue,
    color: 'white',
    padding: '.5em 2.5em .65em',
    width: 230,
    borderRadius: '1.75em',
    position: 'absolute',
    top: 120,
    left: '50%',
    transform: 'translateX(-50%)',
    '& svg': {
      position: 'absolute',
      width: 7,
      height: 7,
      right: '1em',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    width: '100%',
    textAlign: 'center',
  },
}))

let scrollTimeline, initialTimeline

const getSectionBySlug = (sections, slug) => {
  return sections.find((section) => section.slug.current === slug.replace('/', ''))
}

const IndexPage = (props) => {
  const { data, errors } = props
  // console.log('IndexPage props', props)
  const { localizedSiteSettings, localizedSections } = data
  const { language } = localizedSiteSettings
  const sections = flattenSectionsFromGraphQL(localizedSections)
  const [welcomeScreenShown, setWelcomeScreenShown] = useLocalStorage('welcomeScreenShown', false)
  const [dismissedTouchNotice, setDismissedTouchNotice] = useLocalStorage(
    'dismissedTouchNotice',
    false
  )
  // const [dismissedTouchNotice, setDismissedTouchNotice] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [activeBlock, setActiveBlock] = useState('')
  const classes = useStyles()
  const supportsHover = useMediaQuery('(hover:hover)')
  const mobile = useMediaQuery(baseTheme.breakpoints.down('sm'))

  const key = isClient ? 'clientRender' : 'serverRender'

  const slugs = {
    understanding: '/understanding-stress-and-trauma',
    building: '/building-strength-and-capacity',
    social: '/social-emotional-learning',
    activities: '/activities',
  }

  useEffect(() => {
    setIsClient(true)

    return () => {
      if (initialTimeline) {
        initialTimeline.kill(true)
        initialTimeline = undefined
      }
      if (scrollTimeline) {
        scrollTimeline.kill(true)
        scrollTimeline = undefined
      }
      gsap.killTweensOf('*')
      gsap.set(document.body, {
        backgroundColor: '#fff8f0',
      })
    }
  }, [])

  const scrollToEnd = () => {
    gsap.to(window, { duration: 1.5, scrollTo: 400 })
  }

  const welcomeArrowRef = useCallback((node) => {
    if (initialTimeline) {
      initialTimeline.kill(true)
      initialTimeline = undefined
    }
    if (node) {
      initialTimeline = gsap.timeline()
      initialTimeline.delay(1)
      initialTimeline.addLabel('start')
      // initialTimeline.fromTo(
      //   node,
      //   {
      //     height: '0%'
      //   },
      //   {
      //     height: '99%',
      //     duration: 3.5 / 2,
      //     ease: 'power2.in'
      //   },
      //   'start'
      // )

      initialTimeline.addLabel('rebound')
      initialTimeline.to(
        node,
        {
          height: '3%',
          duration: 3.5 / 4,
          ease: 'power2.out',
        },
        'rebound'
      )
      initialTimeline.to(
        node,
        {
          borderRightColor: 'transparent',
          ease: 'power2.out',
          duration: 3.5 / 4,
        },
        'rebound'
      )

      let bounceTimeline = gsap.timeline({
        repeat: -1,
        yoyo: true,
      })
      bounceTimeline.to(node, {
        height: '4%',
        duration: 3.5 / 4,
        ease: Power1.easeIn, //'elastic.out(1, 0.4)'
      })
      initialTimeline.add(bounceTimeline, 'bounce')
    }
  })

  const layoutRef = useCallback((node) => {
    if (scrollTimeline) {
      scrollTimeline.kill(true)
      scrollTimeline = undefined
    }
    if (node) {
      scrollTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: node,
          start: function () {
            return 'top top-=2'
          },
          end: function () {
            return `+=400`
          },
          scrub: true,
          onEnter: function () {},
          onEnterBack: function () {},
          onScrubComplete: () => {},
        },
      })

      scrollTimeline.addLabel('start')
      scrollTimeline.fromTo(
        node.querySelector('.scrollSpacer'),
        {
          backgroundColor: '#FFC433',
        },
        {
          backgroundColor: '#fff8f0',
          duration: 0.5,
        },
        'start'
      )
      scrollTimeline.to(
        node.querySelector('.welcomeContentContainer'),
        {
          opacity: 0,
          duration: 0.5,
        },
        'start'
      )
      scrollTimeline.to(
        node.querySelector('.welcomeText'),
        {
          y: -40,
          duration: 0.5,
        },
        'start'
      )
      scrollTimeline.to(
        node.querySelector('.welcomeImageContainer'),
        {
          y: 40,
          duration: 0.5,
        },
        'start'
      )
      scrollTimeline.addLabel('landingGone')
      scrollTimeline.fromTo(
        node.querySelector('.homeContentContainer'),
        {
          opacity: 0,
          scale: 1.05,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 1,
        },
        'landingGone'
      )
      scrollTimeline.addLabel('arrived', '-=.5')
      scrollTimeline.set(
        node.querySelector('.homeContentContainer'),
        {
          pointerEvents: 'all',
        },
        'arrived'
      )
      scrollTimeline.call(handleActivateHome, undefined, '-=.1')
    }
  }, [])

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }

  const site = (data || {}).site

  const handleActivateHome = () => {
    setWelcomeScreenShown(true)
  }

  const handleBlockClick = (e, slug) => {
    handleDismissTouchNotice()
    if (supportsHover) {
      return
    }
    if (activeBlock === slug) {
    } else {
      e.preventDefault()
      setActiveBlock(slug)
    }
  }

  const handleBlockMouseEnter = (e, slug) => {
    if (supportsHover) {
      setActiveBlock(slug)
    }
  }

  const handleDismissTouchNotice = () => {
    setDismissedTouchNotice(true)
  }

  const [layoutVisible, setLayoutVisible] = useState(false)
  useEffect(() => {
    if (welcomeScreenShown) {
      setTimeout(() => {
        window.scrollTo(0, 400)
        setLayoutVisible(true)
      }, 15)
    } else {
      setLayoutVisible(true)
    }
  }, [welcomeScreenShown])

  const render = () => {
    return (
      <LayoutContainer
        key={key}
        internalScroll={true}
        ref={layoutRef}
        className={layoutVisible ? classes.layoutVisible : classes.layoutInvisible}
        language={localizedSiteSettings.language}
        siteSettings={localizedSiteSettings}
        sections={localizedSections}
      >
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        <div className={clsx(classes.scrollSpacer, 'scrollSpacer')}></div>
        {renderWelcomeScreen()}
        {renderHome()}
      </LayoutContainer>
    )
  }

  const renderWelcomeScreen = () => {
    return (
      <ContentContainer
        className={clsx(classes.welcomeContentContainer, 'welcomeContentContainer')}
      >
        <div className={classes.welcomeContent}>
          <Typography className={clsx(classes.welcomeText, 'welcomeText')}>
            {convertLineBreakToBr(localizedSiteSettings.description)}
          </Typography>
          <Typography className={clsx(classes.welcomeScrollText)} variant="h2">
            {localizedSiteSettings.scrollToExplore}
          </Typography>
        </div>
        <button
          className={clsx(classes.welcomeImageContainer, 'welcomeImageContainer')}
          onClick={scrollToEnd}
        >
          <div className={clsx(classes.welcomeArrow, 'welcomeArrow')} ref={welcomeArrowRef}></div>
          <img
            src={welcomeFigureImage}
            className={classes.welcomeImage}
            width="862"
            height="1028"
          />
          <img
            src={welcomeStarImage}
            className={clsx(classes.welcomeImage, classes.welcomeStarImage)}
            width="862"
            height="1028"
          />
        </button>
      </ContentContainer>
    )
  }

  const renderHome = () => {
    return (
      <ContentContainer className={clsx(classes.homeContentContainer, 'homeContentContainer')}>
        {!supportsHover && !dismissedTouchNotice && (
          <ButtonBase className={classes.touchNotice} onClick={handleDismissTouchNotice}>
            {localizedSiteSettings.introInstructions}
            <CloseIcon className={classes.touchNoticeClose} />
          </ButtonBase>
        )}
        <div className={classes.homeBlocksContainer}>
          <LocalizedLink
            currentLanguage={language}
            to={slugs.understanding}
            className={clsx(classes.homeBlock, classes.homeBlockTrauma, {
              active: activeBlock === slugs.understanding,
            })}
            onClick={(e) => handleBlockClick(e, slugs.understanding)}
            onMouseEnter={(e) => handleBlockMouseEnter(e, slugs.understanding)}
          >
            <img src={traumaImage} className={clsx(classes.blockImage, 'default-img')} />
            <img src={traumaHoverImage} className={clsx(classes.blockImage, 'hover-img')} />
            <Typography variant="h1" className={clsx(classes.blockTitle, 'block-title')}>
              {getSectionBySlug(sections, slugs.understanding).title}
            </Typography>
          </LocalizedLink>
          <LocalizedLink
            currentLanguage={language}
            to={slugs.building}
            className={clsx(classes.homeBlock, classes.homeBlockStrength, {
              active: activeBlock === slugs.building,
            })}
            onClick={(e) => handleBlockClick(e, slugs.building)}
            onMouseEnter={(e) => handleBlockMouseEnter(e, slugs.building)}
          >
            <img src={strengthImage} className={clsx(classes.blockImage, 'default-img')} />
            <img src={strengthHoverImage} className={clsx(classes.blockImage, 'hover-img')} />
            <Typography variant="h1" className={clsx(classes.blockTitle, 'block-title')}>
              {getSectionBySlug(sections, slugs.building).title}
            </Typography>
          </LocalizedLink>
          <LocalizedLink
            currentLanguage={language}
            to={slugs.social}
            className={clsx(classes.homeBlock, classes.homeBlockSEL, {
              active: activeBlock === slugs.social,
            })}
            onClick={(e) => handleBlockClick(e, slugs.social)}
            onMouseEnter={(e) => handleBlockMouseEnter(e, slugs.social)}
          >
            <img src={selImage} className={clsx(classes.blockImage, 'default-img')} />
            <img src={selHoverImage} className={clsx(classes.blockImage, 'hover-img')} />
            <Typography variant="h1" className={clsx(classes.blockTitle, 'block-title')}>
              {getSectionBySlug(sections, slugs.social).title}
            </Typography>
          </LocalizedLink>

          <LocalizedLink
            currentLanguage={language}
            to={slugs.activities}
            className={clsx(classes.homeBlock, classes.homeBlockActivity, {
              active: activeBlock === slugs.activities,
            })}
            onClick={(e) => handleBlockClick(e, slugs.activities)}
            onMouseEnter={(e) => handleBlockMouseEnter(e, slugs.activities)}
          >
            <img src={activityImage} className={clsx(classes.blockImage, 'default-img')} />
            <img src={activityHoverImage} className={clsx(classes.blockImage, 'hover-img')} />

            <Typography variant="h1" className={clsx(classes.blockTitle, 'block-title')}>
              {localizedSiteSettings.activityCorner}
            </Typography>
          </LocalizedLink>
        </div>
        {mobile && (
          <Typography variant="caption" className={classes.footer}>
            {localizedSiteSettings.aResourceSiteBy}{' '}
            <a href="http://counselinginschools.org" target="_blank" rel="noreferrer">
              Counseling In Schools
            </a>
          </Typography>
        )}
      </ContentContainer>
    )
  }

  // Prevent flash of default state by only rendering if isClient
  if (isClient) {
    return render()
  } else {
    return ''
  }
}

export default IndexPage
