import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    marginTop: theme.custom.layout.navHeight,
    paddingTop: 75,
    height: `calc((var(--vh) * 100) - ${theme.custom.layout.navHeight}px)`,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      marginTop: theme.custom.layout.navHeightMobile,
      height: `calc((var(--vh) * 100) - ${theme.custom.layout.navHeightMobile}px)`
    }
  }
}))

export default function ContentContainer({ className, children }) {
  const classes = useStyles()

  return <div className={clsx(classes.root, className)}>{children}</div>
}
